@font-face {
  font-family: "Gotham-Book";
  src: url("../fonts/Gotham-Book/Gotham-Book.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham-Book/Gotham-Book.otf") format("opentype"),
    url("../fonts/Gotham-Book/Gotham-Book.woff") format("woff"),
    url("../fonts/Gotham-Book/Gotham-Book.ttf") format("truetype"),
    url("../fonts/Gotham-Book/Gotham-Book.svg#Gotham-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("../fonts/Gotham-Medium/Gotham-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham-Medium/Gotham-Medium.otf") format("opentype"),
    url("../fonts/Gotham-Medium/Gotham-Medium.woff") format("woff"),
    url("../fonts/Gotham-Medium/Gotham-Medium.ttf") format("truetype"),
    url("../fonts/Gotham-Medium/Gotham-Medium.svg#Gotham-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Light";
  src: url("../fonts/Gotham-Light/Gotham-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham-Light/Gotham-Light.otf") format("opentype"),
    url("../fonts/Gotham-Light/Gotham-Light.woff") format("woff"),
    url("../fonts/Gotham-Light/Gotham-Light.ttf") format("truetype"),
    url("../fonts/Gotham-Light/Gotham-Light.svg#Gotham-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("../fonts/Gotham-Bold/Gotham-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham-Bold/Gotham-Bold.otf") format("opentype"),
    url("../fonts/Gotham-Bold/Gotham-Bold.woff") format("woff"),
    url("../fonts/Gotham-Bold/Gotham-Bold.ttf") format("truetype"),
    url("../fonts/Gotham-Bold/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Black";
  src: url("../fonts/Gotham-Black/Gotham-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham-Black/Gotham-Black.otf") format("opentype"),
    url("../fonts/Gotham-Black/Gotham-Black.woff") format("woff"),
    url("../fonts/Gotham-Black/Gotham-Black.ttf") format("truetype"),
    url("../fonts/Gotham-Black/Gotham-Black.svg#Gotham-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 23px;
  font-weight: normal;
}

h1 {
  font: $font4;
  font-size: 25px;
  @include respond-to("medium") {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
  @include respond-to("medium") {
    font-size: 40px;
  }
}

p {
  font: $font2;
  line-height: 1.5;
  margin-bottom: 38px;
}

p:last-of-type {
  margin-bottom: 0px;
}

h4 {
  font-size: 14px;
  line-height: 1.5;
}

h3 {
  font-size: 18px;
  line-height: 1.5;
}

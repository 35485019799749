footer {
  padding: 9px 0px 19px;
  @include respond-to("medium") {
    padding: 0px;
    > .container > .row {
      justify-content: space-between;
      align-items: center;
    }
  }
  @include respond-to("to-medium") {
    > .container > .row {
      display: block;
    }
  }
  background: $blue2;
  overflow: hidden;
  @include respond-to("medium") {
    height: auto;

    > .container > .flex-row-md {
      align-items: center;
    }
  }
  width: 100%;
  .logo {
    display: block;
    padding: 25px 10px 0px;
    @include respond-to("medium") {
      padding: 25px 10px;
    }
    @include respond-to("xlarge") {
      padding: 18px 10px;
    }
    float: left;
    svg {
      width: 60px;
      display: block;
    }
    span {
      height: 60px;
      width: 60px;
      background-color: $gray4;
      border-radius: 50%;
      display: block;
    }
    &-graphic {
      max-height: 60px;
    }
  }

  ul.menu-main {
    position: relative;
    margin: 0px;
    padding: 0px;
    display: inline-block;
    @include respond-to("to-medium") {
      padding: 0px 10px 0;
      margin: 15px 0px;
      width: 100%;
    }
    list-style: none;

    li.menu-main-item {
      a {
        display: block;
        position: relative;
        color: $white;
        text-decoration: none;
        font: $font3;
        font-size: 12px;
        line-height: 1.5;
        @include respond-to("to-medium") {
          line-height: 2.8;
        }
        text-transform: uppercase;
        &:hover,
        &:focus,
        &:active {
          font: $font4;
          line-height: 1.5;
          @include respond-to("to-medium") {
            line-height: 2.8;
          }
        }
        @include respond-to("medium") {
          padding: 20px 10px;
        }
        width: 100%;
        -webkit-tap-highlight-color: transparent;
      }
      margin-top: 5px;

      @include respond-to("medium") {
        float: left;
        margin-top: 0px;
      }

      &.active {
        a {
          font: $font4;
          line-height: 1.5;
          @include respond-to("to-medium") {
            line-height: 2.8;
          }
        }
      }
    }
  }
}

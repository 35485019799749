.products {
  .solutions-single-section {
    padding-top: 0px;
    margin-top: -25px;
    @include respond-to("medium") {
      margin-top: -55px;
    }
  }
  &-single {
    &-section {
      padding-top: 10px;
      .col-md-6 {
        @include respond-to("medium") {
          border-right: 1px solid $gray7;
        }
      }
      &-details {
        h1 {
          font: normal 1em/1 "Gotham-Light", Arial, sans-serif;
          letter-spacing: -1.44px;
          color: #0a0a0a;
          font-size: 25px;
          @include respond-to("medium") {
            font-size: 36px;
          }
          margin-bottom: 5px;
          line-height: 1.5;
        }
        p {
          color: $gray8;
          margin-top: 0px;
          margin-bottom: 15px;
        }
        hr {
          width: 65px;
          border-width: 0px;
          border-bottom: 2px solid #bd9b30;
          float: left;
          margin: 0px;
        }
        &-image {
          display: inline-block;
          width: 100%;
          margin-top: 50px;
          @media (max-width: 400px) {
            img {
              margin-left: 0px !important;
            }
          }
        }
      }
      &-specs {
        h5 {
          font: $font4;
          text-transform: uppercase;
          color: $black4;
        }
        .color-choice {
          input#color {
            background-color: $gray9;
            border: 0px solid $white;
            color: $gray10;
            height: 50px;
            padding: 20px 15px;
            width: 100%;
            max-width: 250px;
          }
          &-list {
            margin-top: 25px;
            list-style: none;
            padding-left: 0px;
            li {
              float: left;
              a {
                display: block;
                height: 23px;
                width: 23px;
                transition: all 0.2s ease-in;
                &:hover,
                &.active {
                  box-shadow: 0px 2px 8px 0px rgba($color: #000, $alpha: 0.5);
                  transform: scale(1.08, 1.08);
                }
              }
              margin-right: 15px;
              margin-bottom: 15px;
            }
            display: inline-block;
            width: 100%;
            @include respond-to("medium") {
              max-width: 90%;
            }
          }
          margin-bottom: 30px;
          @include respond-to("medium") {
            margin-bottom: 65px;
          }
        }
        .electronics {
          &-list {
            padding-left: 0px;
            list-style: none;
            li {
              margin-bottom: 18px;
            }
            &-name {
              padding: 10px;
              text-align: center;
              color: $blue;
              border-radius: 6px;
              background-color: $gray11;
            }
            &-description {
              p {
                font: $font3;
                line-height: 1.5;
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 12px;
                color: $gray8;
              }
            }
          }
        }
      }
      &-list {
        &-single {
          padding-bottom: 30px;
          margin-bottom: 50px;
          @include respond-to("medium") {
            padding-bottom: 50px;
          }
          border-bottom: 1px solid $gray7;
          &:last-of-type {
            border-bottom: 0px solid $gray7;
          }
          &-name {
            display: inline-block;
            width: 100%;
            margin-bottom: 40px;
          }
          h3 {
            font: normal 1em/1 "Gotham-Light", Arial, sans-serif;
            letter-spacing: -1.44px;
            color: #0a0a0a;
            font-size: 25px;
            line-height: 1.5;
            @include respond-to("medium") {
              font-size: 36px;
            }
          }
          hr {
            width: 65px;
            border-width: 0px;
            border-bottom: 2px solid #bd9b30;
            float: left;
            margin: 0px;
          }
          &-image {
            text-align: center;
            display: inline-block;
            img {
              display: block;
            }
            a.download {
              position: relative;
              width: 100%;
              max-width: 220px;
              padding: 15px 30px;
              margin: -35px auto 0;
              text-align: center;
              background-color: $white;
              color: $blue;
              box-shadow: 0px 3px 4px 0px rgba($color: #d7d7d7, $alpha: 0.5);
              img {
                display: inline;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}

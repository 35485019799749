.homepage {
  &-header {
    &-image {
      position: relative;
      width: 100%;
      margin: 25px auto 30px;
      @media only screen and (min-width: 400px) {
        margin: 40px auto;
      }
      @include respond-to("small") {
        margin: 65px 0px;
      }
      @include respond-to("medium") {
        width: 488px;
        float: right;
      }
      &--asset {
        height: auto;
        background: blue;
        box-shadow: 0px 10px 13px 1px rgba($color: #b6b6b6, $alpha: 0.5);
        &-1 {
          position: absolute;
          left: 40%;
          top: 50%;
          transform: translateX(0%) translateY(-50%);
          background-color: $white;
          width: 60%;
        }
        &-2 {
          width: 75%;
        }

        @include respond-to("small") {
          width: auto;
        }
      }
    }
    &-caption {
      margin: 30px 0px;
      h1 {
        line-height: 1.4;
        color: $green2;
      }
      p {
        font: $font2;
        font-size: 14px;
        line-height: 1.786;
      }
      &-buttons {
        display: inline-block;
        margin-top: 22px;
        .btn {
          @include respond-to("medium") {
            float: left;
          }
        }
      }
    }
  }
}

.featured-product {
  color: $white;
  @include respond-to("to-medium") {
    text-align: center;
  }
  &-image {
    @include respond-to("to-medium") {
      max-width: 50%;
      margin: 30px auto;
      img {
        margin: 0px !important;
      }
    }

    text-align: center;
    @include respond-to("medium") {
      margin: 65px 0px;
    }
  }

  &-caption {
    @include respond-to("to-medium") {
      margin-bottom: 15px;
    }
    p {
      line-height: 1.5;
    }
  }
}

.featured-product + .featured-product {
  .featured-product-image {
    img {
      @include respond-to("to-medium") {
        max-height: 200px;
      }
    }
  }
}

.features {
  &-single {
    &-wrap {
      padding: 30px 15px 36px;
      margin: 25px 0px 0px;
      @include respond-to("medium") {
        max-width: 268px;
        margin: 60px 0px;
      }
      @include respond-to("large") {
        padding: 30px 30px 36px;
      }
      @include respond-to("to-medium") {
        padding: 30px 30px 36px;
      }
      background-color: $white2;
      border-radius: 3px;
      box-shadow: 0px 7px 20px 0px rgba($color: #888888, $alpha: 0.5);
      text-align: center;
      &-icon {
        max-height: 44px;
        margin-bottom: 18px;
      }
      h3 {
        font-size: 17px;
        color: $black;
        margin-bottom: 18px;
      }
      p {
        @include respond-to("large") {
          font-size: 14px;
        }
        letter-spacing: -0.3;
        line-height: 1.786;
        margin-bottom: 23px;
      }
      &:hover {
        transform: translate(0px, -5px);
        box-shadow: 0px 7px 20px 0px rgba($color: #3a3939, $alpha: 1);
      }
      transition: all 0.2s ease-in;
    }
    &:nth-child(3n + 2) {
      .features-single-wrap {
        margin-right: auto;
        margin-left: auto;
      }
    }
    &:nth-child(3n + 2) {
      .features-single-wrap {
        margin-right: auto;
        margin-left: auto;
      }
    }
    &:nth-child(3n + 0) {
      .features-single-wrap {
        float: right;
      }
    }
  }
}

.clients {
  > .container {
    @include respond-to("large") {
      max-width: 992px;
    }
  }
  h3 {
    text-align: center;
    color: $gray5;
    margin-bottom: 45px;
  }

  &-list {
    .owl-stage {
      display: flex;
      align-items: center;
      justify-content: center;
      &-outer {
        &:before,
        &:after {
          content: "";
          height: 100%;
          width: 30px;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fafafa+0,ffffff+100&1+0,0+100 */
          background: -moz-linear-gradient(
            left,
            rgba(250, 250, 250, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(250, 250, 250, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(250, 250, 250, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
        }
        &:after {
          left: auto;
          right: 0;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,fafafa+100&0+0,1+100 */
          background: -moz-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(250, 250, 250, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(250, 250, 250, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(250, 250, 250, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fafafa',GradientType=1 ); /* IE6-9 */
        }
      }
    }
    .owl-item {
      text-align: center;
      img {
        display: inline-block;
        width: auto;
      }
    }

    &.owl-carousel {
      text-align: center;
      .owl-dots {
        margin-top: 15px;

        button.owl-dot {
          height: 10px;
          width: 10px;
          background-color: $gray4;
          border-radius: 50%;
          margin: 0px 5px;
          &.active {
            background-color: $blue;
          }
        }
      }
    }
  }
}

// Fonts
// $pfont: Arial
// italic small-caps bold 16px/1.5 Arial, sans-serif;
$font1: normal 1em/1 "Gotham-Light", Arial, sans-serif; // Gotham-light
$font2: normal 1em/1 "Gotham-Book", Arial, sans-serif; // Gotham-Book
$font3: normal 1em/1 "Gotham-Medium", Arial, sans-serif; // Gotham-Medium
$font4: normal 1em/1 "Gotham-Bold", Arial, sans-serif; // Gotham-Bold
$font5: normal 1em/1 "Gotham-Black", Arial, sans-serif; // Gotham-Black

// Cclors
$white: #fff;
$white2: #fafafa;
$black: #0a0a0a;
$black2: #000815;
$black3: #0b0b0b;
$black4: #2b2b2b;
$gray: #6a6a6a;
$gray2: #eaeaea;
$gray3: #535353;
$gray4: #d8d8d8;
$gray5: #7f7f7f;
$gray6: #7e7e7e;
$gray7: #f0f0f0;
$gray8: #656565;
$gray9: #ededed;
$gray10: #646464;
$gray11: #f1f1f1;
$green: #457e4d;
$green2: #103a16;
$green3: #2e6437;
$blue: #5f9ecf;
$blue2: #001220;
$gold: #bd9b30;

header {
  @include respond-to("medium") {
    > .container > .row {
      justify-content: space-between;
      align-items: center;
    }
  }
  @include respond-to("to-medium") {
    > .container > .row {
      display: block;
    }
    > .container {
      max-width: 100%;
    }
  }
  background: $white;
  overflow: hidden;
  height: 65px;
  position: fixed;
  top: 0;
  box-shadow: 1px 1px $gray2;
  @include respond-to("medium") {
    height: auto;

    > .container > .flex-row-md {
      align-items: center;
    }
  }
  width: 100%;
  z-index: 2;
  // position: fixed;
  transition: all 0.4s ease-out, background 1s ease-out;
  &.menu-open {
    height: 100%;
    background: $white;
    transition: all 0.45s ease-out, background 0.8s ease-out;
  }

  .logo {
    display: block;
    padding: 10px;
    @include respond-to("to-medium") {
      padding: 13px 10px;
      margin-left: -10px;
    }
    @include respond-to("xlarge") {
      padding: 18px 10px;
    }
    float: left;
    svg {
      height: 40px;
      width: 75px;
      @include respond-to("medium") {
        height: 61px;
        width: 75px;
      }
      display: block;
    }
    span {
      height: 40px;
      width: 40px;
      @include respond-to("medium") {
        height: 60px;
        width: 60px;
      }
      background-color: $gray4;
      border-radius: 50%;
      display: block;
    }
    &-graphic {
      max-height: 60px;
    }
  }
}

/* Menu List items */

.menu {
  @include respond-to("to-medium") {
    clear: both;
  }
}

header {
  .header-wrap {
    @include respond-to("to-medium") {
      padding: 0px 10px;
      text-align: center;
    }
    // @include respond-to("medium") {
    //   float: left;
    // }
  }
  ul.menu-main {
    position: relative;
    display: block;
    padding: 0px 20px 0;
    margin: 15px 0px;
    @include respond-to("medium") {
      padding: 0px;
      margin: 0px;
    }
    list-style: none;

    li.menu-main-item {
      a {
        display: block;
        position: relative;
        color: $gray;
        &:hover,
        &:focus,
        &:active {
          color: $black2;
        }
        text-decoration: none;
        font-size: 12px;
        text-transform: uppercase;
        @include respond-to("to-medium") {
          padding: 20px 0px;
        }
        @include respond-to("medium") {
          padding: 20px 10px;
        }
        width: 100%;
        -webkit-tap-highlight-color: transparent;
      }
      border-bottom: 1px solid rgba($color: $gray, $alpha: 0.05);
      margin-top: 5px;
      opacity: 0;
      @include respond-to("medium") {
        opacity: 1;
      }
      transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      &:nth-child(1) {
        transition-delay: 0.35s;
      }
      &:nth-child(2) {
        transition-delay: 0.3s;
      }
      &:nth-child(3) {
        transition-delay: 0.25s;
      }
      &:nth-child(4) {
        transition-delay: 0.2s;
      }
      &:nth-child(5) {
        transition-delay: 0.15s;
      }
      &:nth-child(6) {
        transition-delay: 0.1s;
      }
      &:nth-child(7) {
        transition-delay: 0.05s;
      }
      &:last-child {
        border-width: 0px;
      }

      @include respond-to("medium") {
        float: left;
        border-bottom: 0px solid $black;
        margin-top: 0px;
      }

      &.active {
        a {
          color: $black2;
        }
      }
    }
  }
  &.menu-open ul.menu-main li.menu-main-item {
    opacity: 1;
    &:nth-child(1) {
      transition-delay: 0.05s;
    }
    &:nth-child(2) {
      transition-delay: 0.1s;
    }
    &:nth-child(3) {
      transition-delay: 0.15s;
    }
    &:nth-child(4) {
      transition-delay: 0.2s;
    }
    &:nth-child(5) {
      transition-delay: 0.25s;
    }
    &:nth-child(6) {
      transition-delay: 0.3s;
    }
    &:nth-child(7) {
      transition-delay: 0.35s;
    }
  }
}

/* Menu Icon */

.icon-container {
  position: absolute;
  display: inline-block;
  @include respond-to("medium") {
    display: none;
  }
  z-index: 2;
  right: 0;
  /* Simply change property to float left to switch icon side :) */
  height: 65px;
  width: 65px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  #menuicon {
    width: 20px;
    height: 10px;
    position: relative;
    display: block;
    margin: -4px auto 0;
    top: 50%;
    .bar {
      width: 100%;
      height: 1px;
      display: block;
      position: relative;
      background: $gray;
      transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      &.bar1 {
        -webkit-transform: translateY(0px) rotate(0deg);
        transform: translateY(0px) rotate(0deg);
      }
      &.bar2 {
        -webkit-transform: translateY(6px) rotate(0deg);
        transform: translateY(6px) rotate(0deg);
      }
    }
  }
}

.menu-open .icon-container {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  #menuicon .bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.1s;
    &.bar1 {
      -webkit-transform: translateY(4px) rotate(45deg);
      transform: translateY(4px) rotate(45deg);
    }
    &.bar2 {
      -webkit-transform: translateY(3px) rotate(-45deg);
      transform: translateY(3px) rotate(-45deg);
    }
  }
}

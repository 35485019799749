.solutions {
  &-section {
    &-list {
      margin: 0px;
      padding-left: 0px;
      list-style: none;
      &-single {
        &-details-caption {
          padding: 10px 0px;
          @include respond-to("small") {
            padding: 20px 0px;
          }
          h3 {
            font: $font5;
            font-size: 19px;
            @include respond-to("small") {
              font-size: 24px;
            }
            color: $black3;
            line-height: 1.167;
            margin-bottom: 10px;
          }
          h4 {
            margin-bottom: 18px;
            font: $font4;
            font-size: 14px;
            line-height: 1.357;
            color: $gray5;
          }
          p {
            font-size: 14px;
            line-height: 1.386em;
            margin-bottom: 18px;
          }
        }
        margin-bottom: 30px;
        @include respond-to("small") {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  &-single {
    &-section {
      .solutions-section-list-single {
        background-color: $white;
        box-shadow: 0px 0px 20px 0px rgba($color: #888888, $alpha: 0.1);
        margin-bottom: 20px;
        @include respond-to("medium") {
          margin-bottom: 30px;
        }
        &:hover {
          transform: translate(0px, -5px);
          box-shadow: 0px 0px 20px 0px rgba($color: #888888, $alpha: 0.4);
        }
        transition: all 0.2s ease-in;
      }
      .solutions-section-list-single-details-image {
        height: 200px;
        width: 100%;
        text-align: center;
        padding: 30px 30px 0px;
        display: inline-block;
        position: relative;
        @include respond-to("medium") {
          height: 330px;
          padding: 30px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 100%;
        }
      }
      .solutions-section-list-single-details-caption {
        padding: 20px 30px 40px;
        @include respond-to("medium") {
          padding: 0px 40px;
        }
      }
    }
  }
}

@include respond-to("small") {
  .solutions .solutions-section-list-single:nth-child(odd) {
    padding-right: 20px;
  }
  .solutions .solutions-section-list-single:nth-child(even) {
    padding-left: 20px;
  }
}

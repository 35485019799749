.btn {
  font: $font4;
  text-decoration: none;
  display: inline-block;
  padding: 15px 23px;
  background-color: $gray;
  color: $white;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.65px;
  box-shadow: 0px 10px 13px 1px rgba($color: #b6b6b6, $alpha: 0.5);
  &-primary {
    background-color: $green;
  }
  &-outline-primary {
    color: $green;
    background-color: transparent;
    border: 2px solid $green;
    padding: 13px 21px;
  }
  & + .btn {
    margin-top: 15px;
    @include respond-to("small") {
      margin-top: 0px;
      margin-left: 15px;
    }
  }
  &:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 10px 13px 1px rgba($color: #b6b6b6, $alpha: 1);
    &:focus {
      outline: none;
    }
  }
  &:active {
    outline: none;
    transform: translate(0px, 0px);
    box-shadow: inset 0 0 10px rgba($color: #4e4e4e, $alpha: 1);
    transition: all 0s ease-in;
  }
  transition: all 0.2s ease-in;
}

.styled-link {
  font: $font4;
  text-decoration: none;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  color: $blue;
}
